/* You can add global styles to this file, and also import other style files */

@import "variables";
@import "bootstrap/scss/bootstrap";
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
@import '@fortawesome/fontawesome-svg-core/styles.css';

:root {
  --Light-fill: #F9F9F9;
  --Fine-lines: #D3D3D3;
  --Supervin-gr: #efefef;
}

@media only screen and (max-width: 1100px) {
  :root {
    font-size: 14px;
  }
}

@page {
  size: auto;
  margin: 0;
}

@media print {
  .isPrinting app-header {
    display: none;
  }
  .isPrinting app-breadcrumbs {
    display: none;
  }
  .isPrinting app-loading-icon {
    display: none;
  }
  .isPrinting app-product > .container {
    display: none;
  }
  footer.isPrinting {
    display: none;
  }
  .isPrinting app-root app-page app-product app-print-pdf {
    display: block;
  }
  .isPrinting {
    margin-top: 0 !important;
  }
  .isPrinting app-product-sticky-buy-bar {
    display: none;
  }

  html {
    font-size: 16px;
  }

  @media (max-width: 700px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 400px) {
    html {
      font-size: 10px;
    }
  }
}

img, iframe {
  max-width: 100%;
  height: auto;
}

.field-name-body p img {
  height: auto !important;
}

html, body {
  height: 100%;
}

body {
  app-loading-icon {
    &.loading-fullscreen {
      height: calc(100% - 196px - 30px)
    }
  }
}

body {
  font-weight: 100;
  line-height: 1.5rem;
  background-color: $light-grey;
  font-family: "Open Sans", "Segoe UI", ui-rounded;
  color: $color-almost-black;

  --primary-color: #5c0a31;
  --primary-text-color: #ff9812;
  --secondary-color: #ff9812;
}


.button-primary {
  @include btn-primary()
}

.btn-primary-supervin {
  @include btn-primary();
  min-width: 200px;
  padding: 10px 35px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin-top: 15px;
  color: #fff;

  &:hover {
    background-color: #ffa736;
    border-color: #ffa22a;
  }

  &:active {
    background-color: #ffad41;
    border-color: #ffa22a;
  }

  &:disabled {
    opacity: 0.65;
  }
}

.btn {
  border-radius: 0;
  text-transform: uppercase;
  padding: 0.7rem 1.3rem;
  font-size: 0.8rem;

  &:hover {
    color: white;
    text-decoration: none;
  }

  &.btn-primary {
    color: #fff;
  }

  &.btn-light {
    background: transparent;
    border: 2px solid $wine-red-dark;
    color: $wine-red-dark;
    text-transform: uppercase;
    font-weight: bold;
  }
}

a {
  color: $wine-red-light;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: $wine-red-light;
    text-decoration: underline;
  }

  &.link-supervin {
    margin-top: 5px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    color: $wine-text-color;
  }
}

.is-invalid {
  border: 3px solid $red !important;
  padding-left: calc(0.75rem - 2px) !important
}

.invalid-feedback {
  display: block !important;
  font-weight: 500;
  font-size: 0.825rem;
}

.supervin-icon-bottle-check {
  background: url('/assets/images/icons/bottle-check.png') no-repeat;
  height: 24px;
  width: 20px;
  display: inline-block;
}

.supervin-icon-truck {
  background: url('/assets/images/icons/truck.png') no-repeat;
  height: 20px;
  width: 34px;
  display: inline-block;
}

.supervin-span-voucher {
  line-height: 14px;
}

.supervin-icon-store-white {
  background: url('/assets/images/icons/store-white.png') no-repeat;
  height: 39px;
  width: 73px;
  display: inline-block;
}

.supervin-icon-truck-white {
  background: url('/assets/images/icons/truck-white.png') no-repeat;
  height: 42px;
  width: 72px;
  display: inline-block;
}

.supervin-icon-facebook-white-round {
  background: url('/assets/images/icons/facebook-white-round.png') no-repeat;
  height: 41px;
  width: 52px;
  display: inline-block;
}

.supervin-icon-chat {
  background: url('/assets/images/icons/chat.png') no-repeat;
  height: 41px;
  width: 42px;
  display: inline-block;
}

.supervin-icon-calendar {
  background: url('/assets/images/icons/calendar_icon_small.png') no-repeat;
  height: 25px;
  width: 25px;
  display: inline-block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

body {
  app-root {
    ul.nav-tabs {
      border-bottom: none;
      justify-content: center;

      .nav-item {

        > .nav-link {
          border: solid 2px $color-grey;
          border-radius: 0;
          font-weight: bold;
          text-transform: uppercase;
          padding: 8px 28px;
          font-size: 0.875rem;
          color: $wine-red-light;

          &.active {
            background-color: $color-grey;
            color: $light-grey;
          }
        }
      }

    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    app-root {
      ngb-tabset {
        .nav-item {
          flex: auto;

          > .nav-link {
            border: solid 2px $color-grey;
            border-radius: 0;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.7rem;
            padding: 8px 5px;
            color: $wine-red-light;
            margin: 1px;
            text-align: center;


            &.active {
              background-color: $color-grey;
              color: $light-grey;
            }
          }
        }

      }
    }
  }
}


a {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

body {
  ngb-modal-window {
    --bs-modal-zindex: 5000 !important;

    .modal-dialog {
      max-width: 700px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: auto;
    }

    .modal-large {
      max-width: 1200px;
    }
  }
}

.modal.fade .modal-dialog-bottom {
  transform: translate(0, 100px);
}

.modal.show .modal-dialog-bottom {
  transform: none;
}

@include media-breakpoint-down(md) {
  .modal-content {
    height: auto;
    border: none;
    border-radius: unset;
    display: flex;
    width: 100%;
    margin: 0 auto;
  }

  .modal-dialog-bottom .modal-content {
    margin: auto auto 0;
  }
}

//mobile breakpoints start
@include media-breakpoint-down(lg) {
  .hide-element-on-mobile {
    display: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .hide-element-on-desktop {
    display: none !important;
  }
}

//mobile breakpoints end

.stop-scrolling {
  height: 100vh;
  overflow: hidden;

  &.replace-scrollbar {
    padding-right: 15px;
  }
}

.vimeo {
  padding: 56.25% 0 0 0;
  position: relative;
}

.vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-orange {
  color: $text-orange !important;

  p {
    color: $text-orange !important;
  }

  span {
    color: $text-orange !important;
  }

}

.font-serif {
  font-family: "PT Serif", serif;
}

.long-text-description {
  line-height: 1.5rem;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
}

app-instantsearch .ais-RefinementList-list > * {
  display: block;
  margin: 0;
}

ngb-datepicker {
  transform: translate(287px, 38px) !important;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: scaleY(1);
  }

  33% {
    opacity: 1;
    transform: scaleY(1.4);
  }
  64% {
    opacity: .1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(.3);
  }
}


@keyframes rotate1{
  0% {transform: rotate(0deg);}
  50% {transform:rotate(22deg);}
}
@keyframes rotate2{
  0% {transform:rotate(0deg);}
  50% {transform:rotate(-22deg);}
}

.announcekit-widget-iframe-backdrop {
  z-index: 1060 !important;
}

// If we use Angular Image directive fill-mode we should prevent width 100%
img[fill] {
  max-height: 100%;
  position: unset !important;
  object-fit: contain;
  object-position: top left;
}

img[fill].center {
  object-position: center;
}

.disabled, .success {
  position: relative;

  &:before {
    z-index: 10;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .6);
  }
}

.success {
  &:before {
    background-color: #16a34a;
  }

  p {
    color: #ffffff;
    font-size: 20px;
  }
}

.popover-header {
  background-color: white;
  border: none;
  padding-top: 12px;
  font-family: "Open Sans", "Segoe UI", ui-rounded;
}

.popover-body {
  padding-top: 0;
  font-family: "Open Sans", "Segoe UI", ui-rounded;
}

ngb-modal-backdrop {
  z-index: 4900 !important;
}

.alert-success {
  color: rgb(10, 54, 34);
  background-color: rgb(209, 231, 221);
  border-color: rgb(163, 207, 187);
}

ngb-alert {
  border-width: 0 0 0 4px !important;

  &.alert-warning {
    color: black;
  }
}

.shimmer-text {
  color: #b7b7b7 !important;
  cursor: default !important;
}

.shimmer {
  position: relative;
  overflow: hidden;
  background-color: #b7b7b7 !important;
  cursor: default !important;
}

.shimmer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(-45deg, #f0f0f0 25%, #e4e4e4 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  opacity: 0.9;
  filter: grayscale(1);
  animation: shimmerAnimation 2s infinite;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}




.searchbox-wrapper {
  $ais-padding-y: 10px;
  $ais-padding-x: 20px;
  position: relative;

  input {
    $ais-padding-y: 10px;
    $ais-padding-x: 20px;

    color: black;
    width: 100%;
    padding: $ais-padding-y $ais-padding-x;
    background-color: #efefef;
    border-radius: 6px;
    border: none;
    font-size: 0.875rem;

    &::placeholder {
      color: black;
      font-size: 0.875rem;
    }
  }

  .icons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;

    fa-icon {
      padding: $ais-padding-y 16px;
      color: black;
      display: block;
      cursor: pointer;

      &.search-icon {
        border-left: solid 1px;
        border-color: #5c0a31;
      }

      &.reset-icon {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .searchbox-wrapper {
    input {
      font-size: 16px;

      &::placeholder {
        color: black;
        font-size: 0.875rem;
      }
    }
  }
}


.system-text-14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
